<template>
    <div class="message-text">
        <div class="body">
            <div class="noti-title" v-html="title" />
            <div class="noti-title">
                <span>약속이 취소되었어요</span>
            </div>
            <div v-if="usedTicket" class="noti-body">
                {{ scheduleDate }}에 만남 예정이었던 {{ targetInfo }}님이 약속 취소를 요청해주셨습니다. <br /><br />
                소중한 시간 내어주셨을텐데 이렇게 말씀드려 대단히 죄송합니다. 약속 취소를 요청한 상대방에게는 페널티가
                부과되었으며, 회원님이 사용한 만남권은 복구 처리해드렸습니다. <br /><br />
                앞으로, 보다 진정성 있는 만남이 될 수 있는 공간이 될 수 있도록 더욱 부단히 노력하겠습니다. 다시 한 번
                사과 말씀드립니다.
            </div>

            <div class="noti-body" v-else>
                {{ scheduleDate }}에 만남 예정이었던 {{ targetInfo }}님이 약속 취소를 요청해주셨습니다. <br /><br />
                소중한 시간 내어주셨을텐데 이렇게 말씀드려 대단히 죄송합니다. 약속 취소를 요청한 상대방에게는 페널티가
                부과되었습니다. <br /><br />
                앞으로, 보다 진정성 있는 만남이 될 수 있는 공간이 될 수 있도록 더욱 부단히 노력하겠습니다. 다시 한 번
                사과 말씀드립니다.
            </div>
        </div>
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'ActionScheduleChangeCancel',
    props: ['message', 'item'],
    data: () => ({
        stats: [],
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        me() {
            return this.$store.getters.me
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        planDetail() {
            if (this.item) {
                return getPlanNameById(this.item.product_id)
            } else {
                return null
            }
        },
        scheduleDate() {
            const date = new Date(this.content.date_confirmed)
            let month = date.getMonth() + 1
            let day = date.getDate()

            month = month < 10 ? '0' + month : month
            day = day < 10 ? '0' + day : day

            const formattedDate = `${month}월 ${day}일`

            return formattedDate
        },
        targetInfo() {
            const name = this.content.target_name
            const replacedName = name.charAt(0) + 'o' + 'o'

            const age = this.content.target_age
            const job = this.content.target_job

            return `${replacedName} (${age}, ${job})`
        },
        usedTicket() {
            return this.content.used_ticket
        },
        title() {
            return this.content.name
        },
    },
    methods: {},
}
</script>
<style scoped lang="scss">
.body {
    padding: 9px 4px;
    .noti-tester {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        align-items: center;
    }
    .noti-title {
        @include spoqa-f-bold;
        font-size: 17px;
        color: #111111;
        padding: 0 2px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 8px;
        color: #111111;
        line-height: 26px;
    }
    .noti-blue-box {
        margin-top: 8px;
        background: #f0f8ff;
        border-radius: 8px;
        color: #111111;
        padding: 4px 8px;
        font-size: 14px;
        line-height: 24px;
        .noti-time {
            font-weight: bold;
            font-size: 16px;
            line-height: 26px;
        }
    }

    ::v-deep .bottom-button > button {
        height: 40px !important;
    }
}
</style>
